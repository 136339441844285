import React from "react";


function Line() {
    return (
        <div className="HorizontalLine"/>
    );
}

export default Line;
